import { createApp } from 'vue';

import 'vant/es/toast/style';
import 'vant/es/notify/style';
// 引入模块后自动生效
import '@vant/touch-emulator';


import App from './App.vue';
import { router } from './router';

const app = createApp(App);
app.use(router);
app.mount('#app');
